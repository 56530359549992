@font-face {
  font-family: "BauPro-Regular";
  src: url("BauPro.woff2") format("woff2"), url("BauPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BauPro-Medium";
  src: url("BauPro-Medium.woff2") format("woff2"), url("BauPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
